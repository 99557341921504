
/**
 * site
 * ----------------------------------------------------------
 * Site Sass file, includes all dependencies...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2015 Uselab.com
 *
 */

/* site components
------------------------------------------------------------ */
@import 'components/filter-vacancies';
@import 'components/list-news';

/* site modules
------------------------------------------------------------ */
@import 'modules/search-results';
@import 'modules/vacancies';
