
/**
 * core/base/btn/btn.scss
 * ----------------------------------------------------------
 * Button styles, which depends on the Bootstrap classes...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* btn
------------------------------------------------------------ */
.btn-style-1, /* needed for RTE where you can only define a single class for an element. btn.btn-default isn't possible, so we use .btn-default */
.btn-style-2,
.btn-style-3,
.btn-style-4,
.btn-style-5,
.btn-style-6,
.btn-style-7,
.btn-style-8,
.btn,
.btn.primary,
.btn-secondary,
.btn-quaternary,
.btn-default {
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  line-height: 20px;
  padding: 12px 20px 14px 20px;
  text-shadow: none;
  text-decoration: none;
  min-width: 180px;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.btn.btn-sm {
    padding:6px 15px 7px 15px;
}

.btn-style-2 {

    padding:8px 20px 10px 20px;
    white-space:nowrap;
    text-align:left;

    /* create arrow with css */
    &:after {
        position: relative;
        margin-left:10px;
        top:7px;
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-right: 2px solid black;
        border-top: 2px solid black;
        transform: rotate(45deg);
        float:right;
    }
}


/* generic icons, not size dependant
------------------------------------------------------------ */
.icon-search.btn,
.icon-search { 
    background-image:url('/assets/img/icons/icon-search.svg');
    background-repeat:no-repeat; background-position:center center;
    background-size:100% 100%;
    width:42px;
    text-indent:42px;
    white-space:nowrap;
}