
/**
 * scss/site/base/base.scss
 * ----------------------------------------------------------
 * Base and generic styles...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.sixteen-nine > .content {
    background: $theme-brand-color-primary;
}

.intro {
    font-size:$theme-font-size-xl;
    color: $theme-brand-color-gray-1;
}

.pre-title {
    color: $theme-brown-color-1;
    margin-bottom:0.4em;    
    font-size:0.95em;
}

.lite {
    font-weight: normal !important;
}

.medium {
    font-weight: 500 !important;
}

p.alt {
    display:inline-block;
    color:$theme-brand-color-blue-3;
}

.metadata {
    color:$theme-brown-color-1;
    font-size:$theme-font-size-s;
    display:inline-block;
    padding-bottom:0.25em;
}

hr {
    border-top-color: $theme-brand-color-blue-1;
    margin: 30px 0;
}

hr.hr-secondary {
  border-top-color: $theme-brand-color-orange-1;
  margin: 30px 0;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 2em;
}

.caption {
    font-style:italic;
}

.box-shadow {
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.15);
}

ul.reset {
    @include ul-reset();
}
