
/**
 * scss/core/components/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

ul.list-news {
    @include ul-reset();

    .metadata {
        display:block;
        margin-top:-4px;
    }
}

@media (min-width:  map-get($grid-breakpoints, "lg")) {

    ul.list-news {

        @include ul-reset();

        li > a {
            text-decoration:none;
        }

        .block-image-text {
            padding-bottom:30px;

            .image {
                vertical-align:top;
                display:inline-block;
                width:33%;
                margin-right:-4px;
            }

            .text {
                vertical-align:top;
                display:inline-block;     
                width:66%;
                padding: 0 0 20px 20px;
            }

            padding-bottom:30px;
        }
    }

}