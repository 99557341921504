
/**
 * scss/core/components/filter-vacancies
 * ----------------------------------------------------------
 * Alert component...
 *
 * @type       Sass
 * @author     PJ Arts <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

div.filter-vacancies-container {
    position:relative;

    div.filter-vacancies {

        border-top:1px solid $theme-brand-color-blue-1;
        padding: 30px 0;
        
        h4 {
            padding:10px 0;
            margin:0;
            cursor:pointer;
            position:relative;
        }

        h4:after {
            content:'';
            background:url(/assets/img/icons/icon-arrow-down.svg) no-repeat 0 0;
            display:block;
            width:22px;
            height:22px;
            position:absolute;
            right:5px; top:6px;
            transform: scaleY(-1);
        }

        h4.collapsed:after {
            right:5px; top:16px;
            transform: scaleY(1);
        }

        .facet {
            position:absolute;
            right:0px; top:0px;
            width:44px; height:22px;
            text-align:right;
        }

        ul {
            @include ul-reset();
            margin-bottom:30px;
        }

        .option-container {
            
            width:100%;
            float:none;
            margin:0;
            
            input[type=checkbox] + label {
                padding-right:50px;
            }

            input[type=checkbox]:checked + label:before {
                background:white;
                border:8px solid $theme-brand-color-blue-3;
            }

            input[type=checkbox] + label:before {
                width:22px;
                height:22px;
                border:1px solid #979797;
            }

        }
    }
}

@media (min-width: $theme-screen-md) {
    div.filter-vacancies-container {
        div.filter-vacancies {

            border-top:1px solid $theme-brand-color-blue-1;
            padding: 30px 60px 30px 0;

            h4 {
                padding:20px 0;
            }

            h4:after {
                right:0; top:15px;
            }

            h4.collapsed:after {
                right:0; top:25px;
            }
        }
    }
}

.filter-vacancies-search {

    margin-bottom:30px;
    position:relative;

    .icon-search {
        position:absolute;
        right:1px;
        top:1px;
    }
}