
/**
 * core/icon/base
 * ----------------------------------------------------------
 * Base icon definitions...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2015 Uselab.com
 *
 */

/* icon size configuration
------------------------------------------------------------ */
.icon-34px { @include icon-base(34px, 34px); }

.icon-svg {
    display:inline-block;
    width:20px;
    height:20px;
    background-size:20px 20px;
    background-repeat: no-repeat;
    position:relative; 
    top:0;
}


/* import libraries
------------------------------------------------------------ */

.icon-svg.icon-white {
    background-image: url('/assets/img/icons/icon-temp-white.svg');
}

.icon-svg.icon-default {
    background-image: url('/assets/img/icons/icon-temp.svg');
}

.icon-svg.icon-user {
    background-image: url('/assets/img/icons/icon-user.svg');
}

.icon-svg.icon-user-white {
    background-image: url('/assets/img/icons/icon-user-white.svg');
}

.icon-svg.icon-globe {
    background-image: url('/assets/img/icons/icon-globe.svg');
}
.icon-svg.icon-globe-white {
    background-image: url('/assets/img/icons/icon-globe-white.svg');
}

.icon-svg.icon-search {
    background-image: url('/assets/img/icons/icon-search.svg');
}

.icon-svg.icon-search-white {
    background-image: url('/assets/img/icons/icon-search-white.svg');
    background-size:24px 24px;
    background-repeat: no-repeat;
    position:relative;
}

.icon-svg.icon-close {
    background-image: url('/assets/img/icons/icon-close.svg');
}

.icon-svg.icon-close-white {
    background-image: url('/assets/img/icons/icon-close-white.svg');
}

.ul-paged-menu.active {
    .icon-svg.icon-hamburger {
        background-image: url('/assets/img/icons/icon-close.svg');
    }
}

.ul-paged-menu {
    .icon-svg.icon-hamburger {
        background-image: url('/assets/img/icons/icon-hamburger.svg');
    }
}

.icon-svg.icon-hamburger-white {
    background-image: url('/assets/img/icons/icon-hamburger-white.svg');
}

/* --- social media */

.icon-svg.icon-twitter {
    background-image: url('/assets/img/icons/social/icon-twitter.svg');
    background-size:17px 17px;
}

.icon-svg.icon-facebook {
    background-image: url('/assets/img/icons/social/icon-facebook.svg');
    background-size:16px 16px;
}

.icon-svg.icon-linkedin {
    background-image: url('/assets/img/icons/social/icon-linkedin.svg');
    background-size:16px 16px;
}

.icon-svg.icon-youtube {
    background-image: url('/assets/img/icons/social/icon-youtube.svg');
    background-size:20px 20px;
}

.icon-svg.icon-info {
    background-image: url('/assets/img/icons/icon-info.svg');
    background-size:20px 20px;
    padding-left:25px;
}

/* add extra spacing to p element after icons */
[class^="icon-"] + p:not([class]) {
    margin-top:2em;
}

/* --- icons 40x40 inline tekst */
.icon-marker,
.icon-tools,
.icon-hours,
.icon-education,
.icon-email,
.icon-warning,
.icon-phone,
.icon-thumb-up,
.icon-thumb-down,
.icon-check {

    position:relative;
    padding-left:55px;
    padding-top:7px;
    min-height:40px;
    display:list-item;
    list-style:none;
    margin-bottom:0.8em;

    &:before {
        content:'';
        position:absolute;
        left:0; top:0;
        width:40px;
        height:40px;
        background-color:$theme-brand-color-gray-3;
        background-image: url('/assets/img/icons/icon-marker.svg');
        background-size:25px 25px;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.icon-email {
    word-break: break-word;
}



.icon-marker {
    &:before {
        background-image: url('/assets/img/icons/icon-marker.svg');
    }
}

.icon-tools {
    &:before {
        background-image: url('/assets/img/icons/icon-tools.svg');
    }
}

.icon-hours {
    &:before {
        background-image: url('/assets/img/icons/icon-hours.svg');
    }
}

.icon-education {
    &:before {
        background-image: url('/assets/img/icons/icon-education.svg');
    }
}

.icon-email {
    &:before {
        background-image: url('/assets/img/icons/icon-mail.svg');
    }
}

.icon-phone {
    &:before {
        background-image: url('/assets/img/icons/icon-phone.svg');
    }
}

.icon-check {
    &:before {
        background-image: url('/assets/img/icons/icon-check.svg');
    }
}

.icon-warning {
    &:before {
        background-image: url('/assets/img/icons/icon-warning.svg');
    }
}

.icon-thumb-up {
    &:before {
        background-image: url('/assets/img/icons/icon-thumb-up.svg');
    }
}

.icon-thumb-down {
    &:before {
        background-image: url('/assets/img/icons/icon-thumb-down.svg');
    }
}


/* icon sprites
------------------------------------------------------------ */
.icon-sprite-40px {
  overflow: hidden;
  width: 40px;
  height: 40px;
  text-decoration: none;

  &.icon-menu {
    background-image: url('/assets/img/icons/icon-sprite-nav.svg');
    background-size: 40px 160px;
    background-position: 0 0;

    &.user {
      background-position: 0 0;
    }

    &.user-heart {
      background-position: 0 -40px;
    }

    &.user-notification {
      background-position: 0 -80px;
    }

    &.search {
      background-position: 0 -120px;
    }
  }
}

