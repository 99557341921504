
/**
 * scss/site.scss
 * ----------------------------------------------------------
 * Site library...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

/* site 
------------------------------------------------------------ */
@import "shared/shared"; 
@import "site/site";

/* overrule any different box-sizing settings, for example the Umbraco RTE */
* {
    box-sizing: border-box;
}