
/**
 * scss/core/layout/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

.search-results {

}


.search-result-item {

    border-bottom:1px solid $theme-brand-color-gray-2;

    &:last-child {
        border-bottom:0;
        margin-bottom:0;
    }

    padding:0 0 40px 0;
    margin-bottom:35px;

    p {
        margin: 0 0 0.75em 0;
    }

    p:last-child {
        margin-bottom:0;
    }

    a.url {
        font-weight:600;
        font-weight:$theme-font-size-l;
        color:$theme-brand-color-blue-1;
        text-decoration:none;
        &:hover {
            text-decoration:underline;
        }
    }
}

.search-results {
    
    .form-search {
        position:relative;
        .icon-search {
            overflow:hidden;
            background-color:white;
            border:0;
            position:absolute;
            right:8px;
            top:9px;
            z-index:99;
            cursor:pointer;
        }
        input {
            width:100%;
        }
  
    }
}