
/**
 * scss/site/modules/
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @copyright  2018 Uselab.com
 *
 */

.b-show-all-vacancies[aria-expanded="true"] {
    display:none;
    border:1px solid red;
}

.block-image-text {
    h3 {
        word-break: break-word;
    }
}
